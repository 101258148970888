"use client";

import { Box, Typography } from "@mui/material";

import MuiSlider from "@mui/material/Slider";
import React, { useEffect, useRef } from "react";
import Image from "next/legacy/image";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { ProductProps } from "@/type/product";
import Link from "next/link";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";

const settings = (lang: string) => ({
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  nextArrow: lang === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
  prevArrow: lang === "ar" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
  rtl: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
});

type Props = { lang: string; products: ProductProps[]; company: Company };

export default function DealTodaySlider({ lang, products, company }: Props) {
  const { t } = useTranslation(lang, "translation", {});
  const sliderRef = useRef(null);
  useEffect(() => {
    if (sliderRef.current) {
      (sliderRef.current as any).slickGoTo(0);
    }
  }, []);

  return (
    <Slider {...settings(lang)} ref={sliderRef} className="deal-today-slider">
      {products.map((product: ProductProps) => (
        <Box
          key={product.id}
          sx={{
            p: "16px",
            borderRadius: "16px",
            border: "1px solid #919eab1f",
          }}
        >
          <Link
            href={`/product/${product.slug ?? product.productNumber}`}
            style={{ textDecoration: "none", textDecorationColor: "none" }}
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "138px" },
                height: "138px",
                mb: "16px",
                borderRadius: "12px",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <Image
                src={
                  product?.images[0] ||
                  "/images/image-product-card-placeholder.svg"
                }
                alt=""
                layout="fill"
                objectFit="contain"
              />
            </Box>
            <Typography
              className="block-text"
              sx={{
                fontSize: "14px",
                color: "#1C252E",
                fontWeight: "500",
                mb: "4px",
              }}
            >
              {product[`${lang}Name` as "enName" | "arName"]}
            </Typography>
            <Typography
              className="block-text"
              sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
            >
              {`${
                product.isOnSale ? product?.onSalePrice : product?.regularPrice
              } ${t(company?.currency || "USD")}`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mt: "8px",
              }}
            >
              <MuiSlider
                aria-label="Temperature"
                defaultValue={100}
                color="secondary"
                sx={{
                  width: { xs: "50%", lg: "68px" },
                  "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
                }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#919EAB",
                  fontWeight: "400",
                  width: { xs: "50%", lg: "unset" },
                }}
              >
                🔥 100 sold
              </Typography>
            </Box>
          </Link>
        </Box>
      ))}

      {/* <Box
        sx={{
          p: "16px",
          borderRadius: "16px",
          border: "1px solid #919eab1f",
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: "138px"},
            height: "138px",
            backgroundColor: "#f4f6f8",
            mb: "16px",
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Image
            src={"/images/product-2.webp"}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Typography
          className="block-text"
          sx={{
            fontSize: "14px",
            color: "#1C252E",
            fontWeight: "500",
            mb: "4px",
          }}
        >
          Samsung Galaxy
        </Typography>
        <Typography
          className="block-text"
          sx={{ fontSize: "14px", color: "#FF5630", fontWeight: "600" }}
        >
          $97.14
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "8px",
          }}
        >
          <MuiSlider
            aria-label="Temperature"
            defaultValue={50}
            color="secondary"
            sx={{
              width: { xs: '50%', lg: "68px"},
              "& .MuiSlider-thumb ": { width: "0px", height: "0px" },
            }}
          />
          <Typography
            sx={{ fontSize: "12px", color: "#919EAB", fontWeight: "400", width: { xs: '50%', lg: "unset" } }}
          >
            🔥 50 sold
          </Typography>
        </Box>
      </Box> */}


    </Slider>
  );
}
