import React from "react";
import InlineLightBox from "@/components/light-box";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export default function ProductViewModal({
  open,
  handleClose,
  product,
}: {
  open: boolean;
  handleClose: () => void;
  product: any;
}) {
  const imageSlides = product.image?.map((img: string) => ({
    src: img,
  }));

  return (
    <Modal
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
        width: "100%",
        height: "100%",
      }}
      open={open}
    >
      <Box
        sx={{
          position: "relative",
          height: { xs: "90%", lg: "80%" },
          width: { xs: "90%", lg: "80%" },
          bgcolor: "#fff",
          top: "0px",
        }}
      >
        <Box
          sx={{ position: "absolute", top: "10px", right: "10px", zIndex: 155 }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#222222",
              "&:hover": {
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "rotate(180deg)",
                },
              },
            }}
            onClick={handleClose}
          />
        </Box>

        <Grid
          container
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Grid item xs={12} lg={6} position="relative">
            <Box
              sx={{
                height: { xs: "400px", lg: "calc(100% - 60px)" },
                mb: { lg: "10px" },
              }}
            >
              <InlineLightBox slides={imageSlides} plugins={[Thumbnails]} />
            </Box>

            <Button
              sx={{
                width: { xs: "90%", lg: "100%" },
                margin: "0 auto",
                zIndex: 100,
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                bgcolor: "#222222",
                textDecoration: "none",
                borderRadius: "0px",
                textTransform: "none",

                "&:hover": {
                  bgcolor: "#222222",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "500",
                  letterSpacing: "0.05em",
                }}
              >
                View full details
              </Typography>
              <ArrowRightAltIcon
                sx={{ width: "18px", height: "18px", color: "#fff" }}
              />
            </Button>

            <Box
              sx={{
                position: "absolute",
                top: { xs: "50px", lg: "10px" },
                right: "10px",
                zIndex: 155,
              }}
            >
              <FavoriteBorderIcon
                sx={{
                  cursor: "pointer",
                  color: "#222222",
                }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              p: { xs: "15px", lg: "50px" },
              mt: { xs: "10px", lg: "0px" },
              height: "100%",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: "500", color: "#151515" }}
            >
              {product.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
