"use client";

import React from "react";
import { Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Box, Typography } from "@mui/material";
import Image from "next/legacy/image";

export default function HeroSlider({ slides }: { slides: any }) {
  return (
    <Swiper
      modules={[Autoplay]}
      loop={true}
      spaceBetween={45}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {slides.map((slide: any, index: number) => (
        <SwiperSlide key={index}>
          <Box
            sx={{
              background: slide.bgColor,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: { xs: "center", lg: "space-between" },
              alignItems: { xs: "center", lg: "flex-start" },
              px: { lg: "120px" },
              height: { xs: "400px", lg: "calc(100vh - 62px)" },
              pt: { xs: "50px", lg: "150px" },
              position: "relative",
            }}
          >
            <Box
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  ml: { lg: "80px" },
                  fontSize: { lg: "40px" },
                  fontWeight: "500",
                  mb: { lg: 5 },
                }}
              >
                {slide.title}
              </Typography>
              {slide.highlight && (
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { lg: "60px" },
                    fontWeight: "bold",
                    color: "#FFC107",
                    mb: 1,
                  }}
                >
                  {slide.highlight}
                </Typography>
              )}
              <Typography
                variant="h5"
                sx={{ fontSize: { lg: "60px" }, fontWeight: "bold", mb: 2 }}
              >
                {slide.subtitle}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { lg: "30px" },
                  position: "absolute",
                  bottom: { xs: "10px", lg: "30px" },
                }}
              >
                {slide.description}
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: "200px", lg: "400px" },
                height: { xs: "200px", lg: "400px" },
                position: "relative",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Image
                src={slide.image}
                alt={slide.title}
                layout="fill"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
