"use client";
import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React from "react";
import Image from "next/legacy/image";
import { ShoppingCart, Visibility, CompareArrows } from "@mui/icons-material";
import { useRouter } from "next/navigation";

export default function ProductCard({
  product,
  handleOpenQuickView,
  handleSetProduct,
}: {
  product: any;
  handleOpenQuickView: () => void;
  handleSetProduct: (product: any) => void;
}) {
  const onProductQuickView = () => {
    handleOpenQuickView();
    handleSetProduct(product);
  };

  const route = useRouter();

  return (
    <Box
      sx={{
        position: "relative",
        px: { xs: "7px", lg: "15px" },
        borderRadius: 3,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "15px",
          left: "30px",
          zIndex: 20,
          fontWeight: "bold",
          background: "#ff4e00",
          width: "44px",
          height: "44px",
          borderRadius: "100%",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
          {`-${product.discount}%`}
        </Typography>
      </Box>

      <IconButton
        sx={{
          position: "absolute",
          top: "10px",
          right: "-7px",
          zIndex: 20,
          width: { xs: "32px", lg: "40px" },
          height: { xs: "32px", lg: "40px" },
          color: "#222222",
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#fff",
          },
        }}
      >
        <FavoriteBorderIcon />
      </IconButton>

      <Box
        sx={{
          position: "relative",
          width: { xs: "180px", lg: "244px" },
          height: { xs: "180px", lg: "244px" },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            opacity: 0,
            transition: "opacity 0.3s ease",
          },
          "&:hover::before": {
            opacity: 1,
          },
          "&:hover .icons": {
            opacity: 1,
            transform: "translate(0)",
          },
        }}
      >
        <Image
          src={product.image[0]}
          alt={product.name}
          layout="fill"
          objectFit="contain"
          style={{ zIndex: -1 }}
        />

        <Box
          className="icons"
          sx={{
            position: "absolute",
            opacity: 0,
            display: "flex",
            alignItems: "center",
            gap: "10px",
            top: "40%",
            left: "20%",
            transform: "translateY(50%)",
            transition: "all 0.3s ease",
          }}
        >
          <Box
            sx={{
              width: "45px",
              height: "45px",
              bgcolor: "#fff",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Quick shop" placement="top">
              <ShoppingCart sx={{ color: "#222222" }} />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: "45px",
              height: "45px",
              bgcolor: "#fff",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onProductQuickView}
          >
            <Tooltip title="Quick View" placement="top">
              <Visibility sx={{ color: "#222222" }} />
            </Tooltip>
          </Box>

          <Box
            sx={{
              width: "40px",
              height: "40px",
              bgcolor: "#fff",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Compare" placement="top">
              <CompareArrows sx={{ color: "#222222" }} />
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          height: "32px",
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          border: "1px solid #ddd",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "calc(100% / 3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ShoppingCart fontSize="small" sx={{ color: "#151515" }} />
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "calc(100% / 3)",
            borderLeft: "1px solid #ddd",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onProductQuickView}
        >
          <Visibility fontSize="small" sx={{ color: "#151515" }} />
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "calc(100% / 3)",
            borderLeft: "1px solid #ddd",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CompareArrows fontSize="small" sx={{ color: "#151515" }} />
        </Box>
      </Box>

      <Box 
        sx={{ cursor: 'pointer'}}
        // onClick={() => route.push(`/product/${product.id}`)}
        onClick={() => route.push(`/product/2`)}
      >
        <Typography
          className="block-text text-1"
          sx={{
            fontSize: "13px",
            mb: "6px",
            color: "#27262c",
            mt: { xs: "30px", lg: "8px" },
          }}
        >
          {product.name}
        </Typography>

        <Box mb={"6px"}>
          <Box sx={{ display: "flex", gap: "5px", alightItem: "center" }}>
            <Typography
              color="text.secondary"
              sx={{
                textDecoration: "line-through",
                color: "#BFBEC8",
                fontSize: "14px",
              }}
            >
              {product.currency} {product.oldPrice}
            </Typography>
            <Typography
              sx={{
                color: "#E33131",
                fontSize: "14px",
                display: "inline",
                fontWeight: "500",
              }}
            >
              {` From ${product.currency}`}
            </Typography>
          </Box>

          <Typography
            sx={{ fontSize: "15px", color: "#E33131", fontWeight: "500" }}
          >
            {product.newPrice}
          </Typography>
        </Box>

        <Box display="flex" gap={1} flexWrap={"wrap"}>
          {product.colors.map((color: string, index: number) => (
            <Box
              key={index}
              sx={{
                border: "2px solid #e5e5e5",
                borderRadius: "50%",
                "&:hover": { border: "2px solid #000" },
              }}
            >
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  backgroundColor: color,
                  boxShadow: "0 0 3px rgba(0,0,0,0.3)",
                  cursor: "pointer",
                  p: "2px",
                  m: "4px",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
