"use client";
import { Backdrop, Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";


import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ProductCard from "./product-card";
import ProductViewModal from "./product-view-popup";


export default function ProductsView({ data }: { data: any }) {
  const [openQuickView, setOpenQuickView] = useState(false);
  const [viewProduct, setViewProduct] = useState({});

  const handleClose = () => {
    setOpenQuickView(false);
  };
  const handleOpenQuickView = () => {
    setOpenQuickView(true);
  };

  const handleViewProduct = (product: any) => {
    console.log(product);
    setViewProduct(product);
  };

  return (
    <Box sx={{ mx: { lg: "40px" }, px: "15px", mb: "80px" }}>
      <Typography
        sx={{
          color: "#151515",
          fontSize: "16px",
          mb: "30px",
          fontWeight: "600",
        }}
      >
        {data.title}
      </Typography>

      <Box>
        <Swiper
          //   modules={[Navigation]}
          spaceBetween={45}
          slidesPerView={5}
          //   navigation
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            760: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
        >
          {data.sourceData.map((item: any, index: number) => (
            <SwiperSlide key={index}>
              <ProductCard
                handleOpenQuickView={handleOpenQuickView}
                product={item}
                handleSetProduct={handleViewProduct}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <ProductViewModal
        open={openQuickView}
        handleClose={handleClose}
        product={viewProduct}
      />
    </Box>
  );
}
